.projects:not(#index) main {	

	& > section {

		@include media(">phone") {
			margin-top:calc($gap * 1.5);
			margin-bottom:calc($gap * 1.5);
		}

	}

	.prose {

		h1 {
			@include grid_cell(5);

			span {
				display:none;
			}

			@include media(">phone") {

				span {
					display:inline-block;					
				}

			} 

		}		

		em {
			
			@include grid_cell(1);

		}		

	}

	#poi {		

		// @include grid(0); 
		// @include grid_cell(6, $gutter:0);

		@include grid();

		.js & { 

			.tab { 
				
				display:none;

			}
			
			// Selected tabs
			@for $i from 1 through 6 {
				
				&.tab-#{$i} a.tab-#{$i} {

					color:$red;

				}

				&.tab-#{$i} #tab-#{$i}.tab {

					display:block;

					@include media(">phone") {
						display:flex;
					}

				}
			}
		}

		nav#tabs {											

			@include grid_cell(6); 

			@include media(">phone") {
				@include grid_cell(5);	
			}

			justify-content: space-between; border-bottom:1px solid $underline; margin-bottom:$gap; flex-wrap:wrap;

			a { 
				padding-top:calc($gap * 2);
				padding-bottom:calc($gap * 2);				
				
				@include media(">phone") {
					padding-top:0;
					padding-bottom:calc($gap / 2); 	
				}				

				border-bottom:none; 
				// flex-grow: 1; flex-basis:0;
				// background:red;

				text-align:center; white-space:nowrap;


				&:last-child {

					text-align: right;

				}

				&:first-child {

					text-align: left;

				}

				&:hover { 
					
					color:$red;
				
				}

				@include media("<=phone") {
					@at-root #exe-valley-farm.projects:not(#index) main #poi nav#tabs {

						[href*="#tab-3"] {
							text-align:right;
						}

						[href*="#tab-4"] {
							text-align:left;							
						}
					}

					flex-grow:1; flex-basis:33.333%;
				}
			}

		}

		.tab { 

			@include grid_cell(6); 

			@include media(">phone") {
				@include grid_cell(5);	
			}			

			margin-bottom:$gap;

			.js & { 
			
				margin-bottom:0;

			}

			.prose {
				width:100%;
				margin-bottom:calc($gap * 2);		

				@include media(">phone") {
					width:37.73584906%; 
					margin-bottom:0;
				}
				
				margin-right:$gap;
				display:block;

				p {
					margin:0;  
				}

				li, p {
					text-indent:calc($indent / 2);
				}

				p, li{ 

					line-height: 1.7em;
					
				}

				blockquote {

					color:$cool_gray_7;

				}
			
			}

			.supporting_image { 

				width:100%;				

				@include media(">phone") {
					width:58.49056604%;
				}

				// nested col 3 of 5				
				display:block; 

				@include media("<=phone") {
					background:$image_filler;
				}
			}

		}

	}

	 .prose ~ #poi {

		margin-top:calc($gap * 3);

	}


	#additional_information {

		padding-left:$gap;
		padding-right:$gap;

		@include media(">phone") {
			padding-left:0;
			padding-right:0;
		}

		@include grid();
		display:flex; flex-wrap:wrap; justify-content:flex-start;

		margin-top:calc($gap * 3);
		margin-bottom:calc($gap * 3);
		
		section {			
			@include grid_cell(3);
			display:block;

			@include media("<=phone") {				
				margin-bottom:calc($gap * 4);

				&#project_accolades {
					width:100%;
				}
			}
			
			@include media(">phone") {
				@include grid_cell(2);
				display:block;
			}
			
			margin:0;

			h2, dd {

				margin-bottom:1.7em;

			}

			dd:last-of-type {

				margin-bottom:0;
			}

			dd + dd {

				margin-top:-1.7em;

			}

			dl {

				margin-left:16pt;
				
				@include media(">phone") {
					margin-left: 36pt;
				}

				@include media(">desktop") {
					margin-left: 74pt;
				}							

			}

		}

	}

	#prev_next {		

		@include grid();
		margin-top:calc($gap * 5);
		margin-bottom:calc($gap * 5);

		padding-left:$gap;
		padding-right:$gap;		

		@include media(">phone") {		
			padding-left:0;
			padding-right:0;			
			margin-top:calc($gap * 3);
			margin-bottom:calc($gap * 3);
		}
		
		flex-wrap:wrap;

		a {
			@include grid_cell(3); align-items:center; border-bottom:none;			

			> div { 

				background:$cool_gray_11;
				position:relative;

				width:65%;
				
				@include media(">phone") {
					// 1 col + margin in a 3 col
					width:34.375%;
					margin:0 $gap;
				}					

				em, .thumb {
					transition:opacity $timing;
				}				

				em {
					display:none; 
					opacity:0; 					
					display:flex; justify-content:center; align-items:center;
					width:100%; height:100%; background:$red; top:0; left:0;
					position:absolute;	
					text-align:center;				
				}

				.thumb, img {	
					width:100%; height:auto; display:block;				
				}									
			}

			&:hover {

				> span {
					color:$red;
				}
			
				em {
					opacity:1; color:$white;
				}

				.thumb {
					opacity:0;
				}

			}

			> span {

				display:block; 

				i {
					display:none;
				}
				
				@include media("<=phone") {
					margin:0 $gap;				
				}

				@include media(">320px") {
					i { 

						display:inline;

					}
				}

			}			

			&:first-of-type {
				
				justify-content:flex-end;

			}

		}

	}

}

.manual_carousel {
	> div:first-child {

		@include grid_cell(6);
		order:1;

		@include media(">phone") { 
			@include grid_cell(5);
			order:0;
		}
	}		
}	



.manual_carousel, .auto_gallery {
	@include grid();		

	align-items:top; justify-content:space-between; margin-top:0;		

	* {
		width:100%;
	}		

	.slick-list {			
		overflow:visible;
	}

	.manual_carousel__next_prev {
		z-index:99;

		@include grid_cell(6);		
		margin-bottom:calc($gap * 1.5);
		padding:0 $gap;		

		@include media(">phone") { 			
			@include grid_cell(1);
			padding:0; margin:0;
		}

		justify-content:space-between; 
		align-items:center;
		
		.prev, .next {				
			border-bottom:0;
			display:block; width:50%; cursor:pointer;
			text-align:center;

			&:hover {
				color:$red;
			}
		}

		.next {			
			@include media(">phone") { 								
				text-align:right;
			}
		}
	}		

	figure {

		cursor:pointer;

		&:only-of-type{
			cursor:default;
		}

	}

	figcaption{
		position:absolute;
		// 2 col within 5
		width:37.73584906%; margin-right:$gap;
		margin-top: calc($gap * 1.5);			
		text-align:center;		

		@include media(">phone") { 
			text-align:justify;
		}			
		
		@include media("<desktop") { 			
			position:relative !important; width:100% !important;
		}

	}

	// Gallery following a gallery		

	@at-root {
		#manor-barn .manual_carousel[data-number="2"],
		#manor-barn .manual_carousel[data-number="3"],
		#manor-barn .manual_carousel[data-number="7"],
		#manor-barn .manual_carousel[data-number="8"],
		#georgian-rectory .manual_carousel[data-number="2"],
		#georgian-rectory .manual_carousel[data-number="3"],
		#pool-barn .manual_carousel[data-number="4"],
		#merchants-house .manual_carousel[data-number="1"],
		#merchants-house .manual_carousel[data-number="4"],	
		#edwardian-house .manual_carousel[data-number="2"],
		#white-barn .manual_carousel[data-number="1"],
		#white-barn .manual_carousel[data-number="4"],
		#quantock-view .manual_carousel[data-number="2"],
		#exe-valley-farm .manual_carousel[data-number="4"],
		#moor-cottage .manual_carousel[data-number="5"],
		#exmoor-lodge .manual_carousel[data-number="2"],
		#exmoor-lodge .manual_carousel[data-number="4"]{

			figcaption {
				position:relative !important; width:100% !important;
			}

			.manual_carousel__next_prev {				
				@include media(">phone") { 			
					padding-bottom:$line-height + ($gap * 1.5);
				}
			}				
		}
	} 		
	
	// Single slide manual carousels
	@at-root {
		#manor-barn .manual_carousel[data-number="3"],
		#manor-barn .manual_carousel[data-number="7"],
		#exmoor-lodge .manual_carousel[data-number="2"],
		#exmoor-lodge .manual_carousel[data-number="4"] {
		
			.manual_carousel__next_prev {
				display:none;
			}
		}
	}
	

	@each $project, $colour in $projects {
	  @at-root ##{$project} .img{		  
	    background-color:$colour;
	  }   
	}

	img{
		width:100%; height:auto;
	}

}

.auto_gallery {

	justify-content:center;

	> div:first-child {

		@include grid_cell(6);

		@include media(">phone") { 
			@include grid_cell(4);
		}
	}	


	figure {
		cursor:default;	
	}	

}