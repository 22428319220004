@font-face{
  font-family:"SangBleuSunrise-Regular-WebXL";
  src:url("/assets/fonts/SangBleuSunrise-Regular-WebXL.eot") format("eot"),url("/assets/fonts/SangBleuSunrise-Regular-WebXL.woff2") format("woff2"),url("/assets/fonts/SangBleuSunrise-Regular-WebXL.woff") format("woff"),url("/assets/fonts/SangBleuSunrise-Regular-WebXL.ttf") format("truetype");  
}

body{
  font-family:"SangBleuSunrise-Regular-WebXL";
  font-variant-numeric:oldstyle-nums;
  -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-smooth:antialiased;
}

.no-font{
  h1, h2, h3, h4, h5, h6, p, li, dt, dd, i, b, em, a, small, sup, figcaption, time, address, details, summary, #legal, .obfs{
    visibility:hidden; opacity:0;
  }
}

h1, h2, h3, h4, h5, h6, p, li, dt, dd, i, b, em, a, small, sup, figcaption, time, address, details, summary, #legal, .obfs{
  visibility:visible; opacity:1; transition:opacity 1000ms;
}

h1, h2, h3, h4, h5, h6, p, li, time, address, figcaption, dl *, #prev_next *, summary, summary *{
  line-height:$line_height; margin-bottom:0;
  font-weight:normal; font-style: normal;  
}

h1, h2, h3, h4, h5, h6, p, em, blockquote, li, summary{
  $map: (1112px:14px, 1280px:18.66px * $scale, 1440px:21px * $scale, 1920px: 28px * $scale, 2560px: 37.333px * $scale);
  @include poly-fluid-sizing('font-size', $map);
}

time, header address, footer, figcaption, aside *, #prev_next *, #poi *, #company, .manual_carousel__next_prev a, .previous-post, .next-post{  
  $map: (1112px:12px, 1280px: 14.93px * $scale, 1440px: 16.8px * $scale, 1920px: 22.4px * $scale, 2560px: 29.866px * $scale);
  @include poly-fluid-sizing('font-size', $map);
}

time, address em, header address, #company{
  color:$cool_gray_7; display:inline;
}

nav#omni a {
  $map: (1112px:10px, 1280px: 10.665px * $scale, 1440px: 12px * $scale, 1920px: 16px * $scale, 2560px: 21.33px * $scale);
  @include poly-fluid-sizing('font-size', $map);
}

#project_index a {
  $map: (1112px:14px, 1280px: 24.89px * $scale, 1440px:28px * $scale, 1920px:37.33px * $scale, 2560px: 49.77px * $scale);
  @include poly-fluid-sizing('font-size', $map);  
}

#signature svg {

  $map: (1112px:12px, 1280px: 12.444px, 1440px: 14px, 1920px: 18.6667px, 2560px: 24.889px);
  @include poly-fluid-sizing('height', $map);
  
}

h1, h2, h3, h4, h5, h6, p {
  margin-top:0;
}

sup {
  vertical-align: initial;
}

h3, h4, h5, h6 {
  color:$cool_gray_7; margin:#{$line_height}em 0; text-indent:0;

  &:first-child {
    margin-top:0;
  }
}

p, li {
  text-indent:calc($indent / 2); 

  @include media(">phone") { 
    text-indent:$indent; 
  }

  hyphens:auto; text-align:justify;
  -ms-hyphenate-limit-lines: 1;
  -webkit-hyphenate-limit-lines: 1;
  hyphenate-limit-lines: 1;


}

a[href^="mailto:"] {
  white-space: nowrap;
}

p{
  margin-bottom:0;

  & + p {
    margin-top:#{$line_height}em;
  }
}

ul, ol {

  margin:0; padding:0; 

}

li {
  
  list-style-type:none; margin:0 0 $gap 0; padding:0;

  &::first-line {
    
    margin: -1em;

  }

}

details,
.article-body {

  li {

    position: relative; text-indent:0;

    &::before {
      
      content: '—';
      position:absolute; 
      left:-1.5em;          
      top:0;

    }

  }


}

blockquote, dl, dt, dd{
  margin:0; padding:0;
}

dl {

  dt {
    color:$cool_gray_7;
  }

  dt, dd {
    line-height:1.7em;  
  }
}

b, strong, i, em, cite{
  font-weight: normal; font-style:normal;
}

figcaption {
  $line_height: $line_height * 1.166;
  line-height:$line_height;
  color:$cool_gray_7; hyphens:auto; text-align:justify;
}

main a, footer a, summary *{
  color:inherit; text-decoration:none; border-bottom:1px solid $underline; display:inline; transition: color $timing;

  &:hover{
    color:$cool_gray_7; border-bottom:1px solid transparent;
  }
}

footer a {

  border-bottom:1px solid transparent; 

  &:hover{
    border-bottom:1px solid $underline;
  }

}