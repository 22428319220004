// Autoplay Animations
@keyframes fadeInDelay {
  0%, 25% {
    opacity: 0;
  }

  75%, 100% {
    opacity: 1;
  }

}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}


.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.img, #film {
  display:block;
    position: relative;
}

.img:after, #film:after {
    content: '';
    display: block;
    height: 0;
    width: 100%;
    // Aspect ratio of images
    padding-bottom: calc((85 / 128) * 100%);        
    content: "";
}

// Landscapes
.l:after { 
    padding-bottom: calc((283 / 420) * 100%);        
    
}

.layout-default.l:after {
  
  @include media("<=phone") {   
      padding-bottom: calc((283 / 200) * 100%); 
    }

}

// Portraits
.p1:after, 
.p1:after,
#prev_next .img:after { 
    padding-bottom: calc((283 / 200) * 100%);        
}

// Practice
#louise-crossman-bio .img:after {
   padding-bottom: calc((1200 / 800) * 100%); 
}

// Default tabs
.tab .img:after {
  padding-bottom: calc((165 / 248) * 100%);        
}

// Tab Exceptions
// Square
#moor-cottage .tab .img:after,
#exe-valley-farm .tab .img:after,
#brendon-estate-farm .auto_gallery[data-number="1"] .img:after,
#manor-barn .auto_gallery[data-number="1"] .img:after,
#pool-barn .auto_gallery[data-number="2"] .img:after,
#pool-barn .tab-1 .img:after {
  padding-bottom: calc((1 / 1) * 100%);        
}

#georgian-rectory .auto_gallery[data-number="2"] .img:after {
  padding-bottom: calc((1410 / 1680) * 100%);        
}

#merchants-house .tab .supporting_image .img,
#quantock-view .tab-2 .supporting_image .img{
  // 2 columns + 1 margin
  max-width:64.51612903%;
}

#georgian-rectory .tab-2 .img:after,
#merchants-house .tab .img:after,
#quantock-view .tab-2 .img:after {
  padding-bottom: calc((3 / 2) * 100%);
}

// Homepage Film
#film:after{
    padding-bottom: 50%;        
}

.img img, #film video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
}

html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;  
}

html {    
    -webkit-text-size-adjust: 100%;  min-height: 0vw;
    line-height: 1.15;

    color: $dark_text;
    font-size: 16px;
    
    background-color:$bg;          
}

body{
  margin:0;
}

header, main, footer, nav, picture, article, section, figure, time{
  display: block;
}

picture {
  line-height:0;
}

h1 {
  font-size: 2em; margin: 0.67em 0;
}

a {
  background-color: transparent;
}

figure{
  margin:0; padding:0;
}

abbr[title] {
  border-bottom: none;
  text-decoration:none;
}

b, strong {
  font-weight: bold; font-style:normal; 
}

img {
  border-style: none;
}

details{
  display: block;
}

summary {
  // display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

section {
  margin:0;
}

::-moz-selection, ::selection{
    background: #000;
    text-shadow: none;
}

:focus{
  outline:none;
}

::-moz-selection {
    background: $cool_gray_1;
    text-shadow: none;
}

::selection {
    background: $cool_gray_1;
    text-shadow: none;
}


img, svg, video{
    vertical-align: middle;
}
