#practice {

	.testimonial {
		@include media(">phone") { 
		
			margin:calc($gap * 1.5) 0 !important;

		}
	}

	main > section {

		@include media(">desktop") {
			margin-top:$gap * 1.5;
			margin-bottom:$gap * 1.5;
		}

		// &#louise-crossman-bio {

		// 	align-items:flex-start;

		// 	figure .img {
		// 		display:none; visibility: hidden;
		// 	}

		// 	p:first-of-type {
		// 		margin-bottom:#{$line_height}em;
		// 	}
			
		// 	.img {
		// 		@include grid_cell(2);
		// 		display:block; visibility:visible;
		// 	}

		// 	@include media("<phone") { 				

		// 		& > .img {
		// 			display:none; visibility: hidden;
		// 		}

		// 		figure { 								
		// 			width:100%;					
		// 			justify-content:flex-end;
				
		// 			.img {
		// 				visibility:visible; float:left;
		// 				// @include grid();
		// 				// @include grid_cell(3);
		// 				width:50vw - ($gap / 2);
		// 				display:block; 
		// 				// @include grid_gutter(0); 
		// 				margin:$gap * 2; margin-left:0;
		// 				margin-left:-16.02901786vw;
		// 			}

		// 			p {
		// 				padding-left:16.02901786vw;
		// 				display:block; 
		// 			}
		// 		}



		// 	}

			

		// } 
	
	}

	#careers {
		margin-bottom:calc($gap * 5);		
		
		@include media(">phone") { 
				
			margin-bottom:calc($gap * 3);

		}

		p { 
			
			display:block ;

		}

	}

	details {

		@include grid_cell(6);

		@include media(">phone") {
			@include grid_cell(5);		
		}	

		flex-wrap:wrap; justify-content:flex-end;

		summary {			

			margin-bottom:$gap * 2;
			
			@include media(">phone") {
				margin-bottom:$gap;					
			}

			width:100%; cursor:pointer; list-style-image: none; justify-content:space-between; border-bottom:none;

			&::-webkit-details-marker{
				display:none;
				background:none;
				color: transparent;				
			}

			span, em {

				border-bottom:1px solid $underline;
				transition: color $timing;

			}
			
			em {
				margin-left:$gap;
			}

			@include media(">phone") {
				em {
					float:right;
				}
			}

			&:hover {

				span, em {

					border-bottom:1px solid transparent; 
					color:$cool_gray_7;

				}
			}

		}

		h2 {

			margin-bottom:#{$line_height}em;

		}

		p, li {
			display:block; border:none; margin-top:0; 
			margin-bottom:#{$line_height}em;
		}

		.agencies, .apply {
		
			text-indent: 0;

		}

		p, ul, h2 {

			// display:block;
			margin-left:16.02901786vw;

			@include media(">phone") {
				// nested 4 cols in 5
				width:79.24528302%;	
				margin-left:20.75471698%;			
			}

		}


	}


}