@charset "UTF-8";
@keyframes fadeInDelay {
  0%, 25% {
    opacity: 0;
  }
  75%, 100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.img, #film {
  display: block;
  position: relative;
}

.img:after, #film:after {
  content: "";
  display: block;
  height: 0;
  width: 100%;
  padding-bottom: 66.40625%;
  content: "";
}

.l:after {
  padding-bottom: 67.380952381%;
}

@media (max-width: 600px) {
  .layout-default.l:after {
    padding-bottom: 141.5%;
  }
}

.p1:after,
.p1:after,
#prev_next .img:after {
  padding-bottom: 141.5%;
}

#louise-crossman-bio .img:after {
  padding-bottom: 150%;
}

.tab .img:after {
  padding-bottom: 66.5322580645%;
}

#moor-cottage .tab .img:after,
#exe-valley-farm .tab .img:after,
#brendon-estate-farm .auto_gallery[data-number="1"] .img:after,
#manor-barn .auto_gallery[data-number="1"] .img:after,
#pool-barn .auto_gallery[data-number="2"] .img:after,
#pool-barn .tab-1 .img:after {
  padding-bottom: 100%;
}

#georgian-rectory .auto_gallery[data-number="2"] .img:after {
  padding-bottom: 83.9285714286%;
}

#merchants-house .tab .supporting_image .img,
#quantock-view .tab-2 .supporting_image .img {
  max-width: 64.51612903%;
}

#georgian-rectory .tab-2 .img:after,
#merchants-house .tab .img:after,
#quantock-view .tab-2 .img:after {
  padding-bottom: 150%;
}

#film:after {
  padding-bottom: 50%;
}

.img img, #film video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}

html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

html {
  -webkit-text-size-adjust: 100%;
  min-height: 0vw;
  line-height: 1.15;
  color: #53565A;
  font-size: 16px;
  background-color: #F5F5F5;
}

body {
  margin: 0;
}

header, main, footer, nav, picture, article, section, figure, time {
  display: block;
}

picture {
  line-height: 0;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

a {
  background-color: transparent;
}

figure {
  margin: 0;
  padding: 0;
}

abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

b, strong {
  font-weight: bold;
  font-style: normal;
}

img {
  border-style: none;
}

details {
  display: block;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

section {
  margin: 0;
}

::-moz-selection, ::selection {
  background: #000;
  text-shadow: none;
}

:focus {
  outline: none;
}

::-moz-selection {
  background: #D9D8D6;
  text-shadow: none;
}

::selection {
  background: #D9D8D6;
  text-shadow: none;
}

img, svg, video {
  vertical-align: middle;
}

@font-face {
  font-family: "SangBleuSunrise-Regular-WebXL";
  src: url("/assets/fonts/SangBleuSunrise-Regular-WebXL.eot") format("eot"), url("/assets/fonts/SangBleuSunrise-Regular-WebXL.woff2") format("woff2"), url("/assets/fonts/SangBleuSunrise-Regular-WebXL.woff") format("woff"), url("/assets/fonts/SangBleuSunrise-Regular-WebXL.ttf") format("truetype");
}
body {
  font-family: "SangBleuSunrise-Regular-WebXL";
  font-variant-numeric: oldstyle-nums;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-smooth: antialiased;
}

.no-font h1, .no-font h2, .no-font h3, .no-font h4, .no-font h5, .no-font h6, .no-font p, .no-font li, .no-font dt, .no-font dd, .no-font i, .no-font b, .no-font em, .no-font a, .no-font small, .no-font sup, .no-font figcaption, .no-font time, .no-font address, .no-font details, .no-font summary, .no-font #legal, .no-font .obfs {
  visibility: hidden;
  opacity: 0;
}

h1, h2, h3, h4, h5, h6, p, li, dt, dd, i, b, em, a, small, sup, figcaption, time, address, details, summary, #legal, .obfs {
  visibility: visible;
  opacity: 1;
  transition: opacity 1000ms;
}

h1, h2, h3, h4, h5, h6, p, li, time, address, figcaption, dl *, #prev_next *, summary, summary * {
  line-height: 1.36;
  margin-bottom: 0;
  font-weight: normal;
  font-style: normal;
}

h1, h2, h3, h4, h5, h6, p, em, blockquote, li, summary {
  font-size: 14px;
}
@media (min-width: 1112px) {
  h1, h2, h3, h4, h5, h6, p, em, blockquote, li, summary {
    font-size: calc(1.0383184524vw + 2.4538988095px);
  }
}
@media (min-width: 1280px) {
  h1, h2, h3, h4, h5, h6, p, em, blockquote, li, summary {
    font-size: calc(1.233984375vw - 0.050625px);
  }
}
@media (min-width: 1440px) {
  h1, h2, h3, h4, h5, h6, p, em, blockquote, li, summary {
    font-size: calc(1.23046875vw + 0px);
  }
}
@media (min-width: 1920px) {
  h1, h2, h3, h4, h5, h6, p, em, blockquote, li, summary {
    font-size: calc(1.2304248047vw + 0.00084375px);
  }
}
@media (min-width: 2560px) {
  h1, h2, h3, h4, h5, h6, p, em, blockquote, li, summary {
    font-size: 31.49971875px;
  }
}

time, header address, footer, figcaption, aside *, #prev_next *, #poi *, #company, .manual_carousel__next_prev a, .previous-post, .next-post {
  font-size: 12px;
}
@media (min-width: 1112px) {
  time, header address, footer, figcaption, aside *, #prev_next *, #poi *, #company, .manual_carousel__next_prev a, .previous-post, .next-post {
    font-size: calc(0.35546875vw + 8.0471875px);
  }
}
@media (min-width: 1280px) {
  time, header address, footer, figcaption, aside *, #prev_next *, #poi *, #company, .manual_carousel__next_prev a, .previous-post, .next-post {
    font-size: calc(0.9861328125vw - 0.0253125px);
  }
}
@media (min-width: 1440px) {
  time, header address, footer, figcaption, aside *, #prev_next *, #poi *, #company, .manual_carousel__next_prev a, .previous-post, .next-post {
    font-size: calc(0.984375vw + 0px);
  }
}
@media (min-width: 1920px) {
  time, header address, footer, figcaption, aside *, #prev_next *, #poi *, #company, .manual_carousel__next_prev a, .previous-post, .next-post {
    font-size: calc(0.9842871094vw + 0.0016875px);
  }
}
@media (min-width: 2560px) {
  time, header address, footer, figcaption, aside *, #prev_next *, #poi *, #company, .manual_carousel__next_prev a, .previous-post, .next-post {
    font-size: 25.1994375px;
  }
}

time, address em, header address, #company {
  color: #99999B;
  display: inline;
}

nav#omni a {
  font-size: 10px;
}
@media (min-width: 1112px) {
  nav#omni a {
    font-size: calc(-0.5960751488vw + 16.6283556548px);
  }
}
@media (min-width: 1280px) {
  nav#omni a {
    font-size: calc(0.7040039063vw - 0.01265625px);
  }
}
@media (min-width: 1440px) {
  nav#omni a {
    font-size: calc(0.703125vw + 0px);
  }
}
@media (min-width: 1920px) {
  nav#omni a {
    font-size: calc(0.7026855469vw + 0.0084375px);
  }
}
@media (min-width: 2560px) {
  nav#omni a {
    font-size: 17.9971875px;
  }
}

#project_index a {
  font-size: 14px;
}
@media (min-width: 1112px) {
  #project_index a {
    font-size: calc(4.1672247024vw - 32.3395386905px);
  }
}
@media (min-width: 1280px) {
  #project_index a {
    font-size: calc(1.6400390625vw + 0.0084375px);
  }
}
@media (min-width: 1440px) {
  #project_index a {
    font-size: calc(1.6400390625vw + 0.0084375px);
  }
}
@media (min-width: 1920px) {
  #project_index a {
    font-size: calc(1.6400390625vw + 0.0084375px);
  }
}
@media (min-width: 2560px) {
  #project_index a {
    font-size: 41.9934375px;
  }
}

#signature svg {
  height: 12px;
}
@media (min-width: 1112px) {
  #signature svg {
    height: calc(0.2642857143vw + 9.0611428571px);
  }
}
@media (min-width: 1280px) {
  #signature svg {
    height: calc(0.9725vw - 0.004px);
  }
}
@media (min-width: 1440px) {
  #signature svg {
    height: calc(0.9722291667vw - 0.0001px);
  }
}
@media (min-width: 1920px) {
  #signature svg {
    height: calc(0.972234375vw - 0.0002px);
  }
}
@media (min-width: 2560px) {
  #signature svg {
    height: 24.889px;
  }
}

h1, h2, h3, h4, h5, h6, p {
  margin-top: 0;
}

sup {
  vertical-align: initial;
}

h3, h4, h5, h6 {
  color: #99999B;
  margin: 1.36em 0;
  text-indent: 0;
}
h3:first-child, h4:first-child, h5:first-child, h6:first-child {
  margin-top: 0;
}

p, li {
  text-indent: 20pt;
  hyphens: auto;
  text-align: justify;
  -ms-hyphenate-limit-lines: 1;
  -webkit-hyphenate-limit-lines: 1;
  hyphenate-limit-lines: 1;
}
@media (min-width: 601px) {
  p, li {
    text-indent: 40pt;
  }
}

a[href^="mailto:"] {
  white-space: nowrap;
}

p {
  margin-bottom: 0;
}
p + p {
  margin-top: 1.36em;
}

ul, ol {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
  margin: 0 0 2.34375vw 0;
  padding: 0;
}
li::first-line {
  margin: -1em;
}

details li,
.article-body li {
  position: relative;
  text-indent: 0;
}
details li::before,
.article-body li::before {
  content: "—";
  position: absolute;
  left: -1.5em;
  top: 0;
}

blockquote, dl, dt, dd {
  margin: 0;
  padding: 0;
}

dl dt {
  color: #99999B;
}
dl dt, dl dd {
  line-height: 1.7em;
}

b, strong, i, em, cite {
  font-weight: normal;
  font-style: normal;
}

figcaption {
  line-height: 1.58576;
  color: #99999B;
  hyphens: auto;
  text-align: justify;
}

main a, footer a, summary * {
  color: inherit;
  text-decoration: none;
  border-bottom: 1px solid #D9D8D6;
  display: inline;
  transition: color 1s;
}
main a:hover, footer a:hover, summary *:hover {
  color: #99999B;
  border-bottom: 1px solid transparent;
}

footer a {
  border-bottom: 1px solid transparent;
}
footer a:hover {
  border-bottom: 1px solid #D9D8D6;
}

#grid {
  width: 100%;
  padding-left: 2.34375vw;
  padding-right: 2.34375vw;
  position: fixed;
  z-index: -1;
  height: 100%;
  opacity: 0.1;
}
@media (min-width: 601px) {
  #grid {
    max-width: 90%;
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 1025px) {
  #grid {
    max-width: 75%;
  }
}
#grid #columns {
  overflow: hidden;
  height: 100%;
}
#grid #columns #cols {
  display: flex;
  flex-wrap: wrap;
  margin-right: -2.34375vw;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
#grid #columns #cols div {
  display: flex;
  width: 16.6666666667%;
  border-right: 0 solid transparent;
  background-clip: padding-box !important;
  border-right-width: 2.34375vw;
  height: 100%;
  background: red;
}
#grid #columns #cols div:nth-child(even) {
  opacity: 0.25;
}
#grid #columns #cols div:nth-child(odd) {
  opacity: 0.5;
}

body {
  margin: 0 auto;
  background: #F5F5F5;
  color: #53565A;
  max-width: 100%;
}
body.grid {
  opacity: 0.75;
}
@media (min-width: 601px) {
  body {
    max-width: 90%;
  }
}
@media (min-width: 1025px) {
  body {
    max-width: 75%;
  }
}

#waypoint {
  width: 100%;
  height: 1px;
  top: 1vh;
  left: 0;
  position: absolute;
}
.grid #waypoint {
  background: red;
}

header#identity_nav {
  min-height: 100px;
  max-height: 250px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 60px;
}
header#identity_nav #logo, header#identity_nav nav {
  opacity: 1;
}
@media (min-width: 768px) {
  header#identity_nav {
    height: calc(12.59765625vw - 36.75px);
  }
}
@media (min-width: 1280px) {
  header#identity_nav {
    height: calc(9.6875vw + 0.5px);
  }
}
@media (min-width: 1440px) {
  header#identity_nav {
    height: calc(9.5833333333vw + 2px);
  }
}
@media (min-width: 1920px) {
  header#identity_nav {
    height: calc(10vw - 6px);
  }
}
@media (min-width: 2560px) {
  header#identity_nav {
    height: 250px;
  }
}
header#identity_nav #logo, header#identity_nav nav, header#identity_nav #omni div {
  display: flex;
  transition: opacity 1s;
  align-items: center;
  justify-content: space-between;
}
header#identity_nav #logo, header#identity_nav nav#omni div {
  width: 100%;
  padding-left: 2.34375vw;
  padding-right: 2.34375vw;
}
@media (min-width: 1025px) {
  header#identity_nav #logo, header#identity_nav nav#omni div {
    padding: 0;
    width: 96.7%;
  }
}
.js.down:not(.top) header#identity_nav #logo {
  position: fixed;
  z-index: 99;
  width: 100%;
}
@media (min-width: 601px) {
  .js.down:not(.top) header#identity_nav #logo {
    width: 90%;
  }
}
@media (min-width: 1025px) {
  .js.down:not(.top) header#identity_nav #logo {
    width: 72.525%;
  }
}
.js.down header#identity_nav #omni {
  background: transparent;
}
.js.down header#identity_nav #omni div {
  transform: translateY(-38px);
  transition-delay: 0.3s;
}
@media (min-width: 1025px) {
  .js.down header#identity_nav #omni div {
    transform: translateY(-2.34375vw);
  }
}
header#identity_nav path {
  transition: fill 0.3s ease;
}
.js header#identity_nav.dark #logo svg path {
  fill: #FFF;
}
header#identity_nav #logo, header#identity_nav #logo a, header#identity_nav #logo svg {
  min-height: 12px;
}
header#identity_nav #logo a {
  width: auto;
  display: block;
}
header#identity_nav #logo svg {
  width: 3.4666666667vw;
  height: auto;
  max-height: 20px;
}
@media (orientation: landscape) {
  header#identity_nav #logo svg {
    width: 1.640625vw;
    max-height: 47px;
  }
}
header#identity_nav #logo svg path {
  fill: #53565A;
}
header#identity_nav nav#omni {
  width: 100%;
  position: fixed;
  top: 0;
  height: 2.34375vw;
  min-height: 38px;
  max-height: 88px;
  background: #F5F5F5;
  justify-content: center;
  z-index: 100;
  transition: background 1s;
}
@media (min-width: 601px) {
  header#identity_nav nav#omni {
    width: 90%;
  }
}
@media (min-width: 1025px) {
  header#identity_nav nav#omni {
    width: 75%;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  header#identity_nav nav#omni {
    left: 50%;
    transform: translate(-50%, 0);
  }
}
header#identity_nav nav#omni div {
  will-change: transform;
  transition: transform 500ms;
  justify-content: space-evenly;
  padding-left: 3.4666666667vw;
  padding-right: 3.4666666667vw;
}
@media (orientation: landscape) {
  header#identity_nav nav#omni div {
    padding-left: 1.640625vw;
    padding-right: 1.640625vw;
  }
}
header#identity_nav nav#omni:hover {
  background: #F5F5F5 !important;
}
header#identity_nav nav#omni:hover div {
  transform: translateY(0) !important;
}
.projects header#identity_nav nav#omni a.projects, .practice header#identity_nav nav#omni a.practice, .contact header#identity_nav nav#omni a.contact, .news header#identity_nav nav#omni a.news {
  color: #89282C;
}
header#identity_nav nav#omni a {
  text-transform: uppercase;
  text-decoration: none;
  transition: color 1s;
  text-align: center;
  color: #53565A;
}
header#identity_nav nav#omni a:hover {
  color: #89282C;
}

main {
  overflow: hidden;
}

main > section {
  margin: 0;
  margin-top: 11.71875vw;
  margin-bottom: 11.71875vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
@media (min-width: 601px) {
  main > section {
    margin-top: 7.03125vw;
    margin-bottom: 7.03125vw;
  }
}
main > section h1 {
  display: flex;
  width: 100%;
  border-right: 0 solid transparent;
  background-clip: padding-box !important;
  border-right-width: 2.34375vw;
  margin-bottom: 4.6875vw;
  justify-content: space-between;
}
@media (min-width: 601px) {
  main > section h1 {
    margin-bottom: 2.34375vw;
  }
}
@media (min-width: 601px) {
  main > section h1 {
    display: flex;
    width: 83.3333333333%;
    border-right: 0 solid transparent;
    background-clip: padding-box !important;
    border-right-width: 2.34375vw;
  }
}
main > section#hero {
  margin-top: 0 !important;
}
main > section#hero .img {
  width: 100%;
  height: auto;
}
main > section#hero .img picture {
  width: 100%;
  height: 100%;
  display: block;
}
main > section#hero .img img {
  width: 100%;
  height: auto;
}
@media (max-width: 600px) {
  main > section.prose, main > section.testimonial, main > section.articles, main > section figcaption, main > section#poi {
    padding-left: 2.34375vw;
    padding-right: 2.34375vw;
  }
}
main > section.prose {
  display: flex;
  flex-wrap: wrap;
  margin-right: -2.34375vw;
}
main > section.prose p {
  display: flex;
  width: 83.3333333333%;
  border-right: 0 solid transparent;
  background-clip: padding-box !important;
  border-right-width: 2.34375vw;
}
@media (min-width: 601px) {
  main > section.prose p {
    display: flex;
    width: 66.6666666667%;
    border-right: 0 solid transparent;
    background-clip: padding-box !important;
    border-right-width: 2.34375vw;
  }
}
main > section.prose figure {
  display: flex;
  width: 50%;
  border-right: 0 solid transparent;
  background-clip: padding-box !important;
  border-right-width: 2.34375vw;
  display: block;
}
main > section.prose figure p {
  border-right: 0 solid transparent;
  background-clip: padding-box !important;
  border-right-width: 0;
  display: block;
  width: 100%;
}
main > section.prose picture {
  width: 100%;
}
main > section.prose img {
  margin-bottom: 2.34375vw;
  width: 100%;
  height: auto;
}
main > section.testimonial {
  display: flex;
  flex-wrap: wrap;
  margin-right: -2.34375vw;
}
@media (min-width: 601px) {
  main > section.testimonial {
    margin: 11.71875vw 0 !important;
  }
}
main > section.testimonial blockquote {
  display: flex;
  width: 83.3333333333%;
  border-right: 0 solid transparent;
  background-clip: padding-box !important;
  border-right-width: 2.34375vw;
  display: block;
}
@media (min-width: 601px) {
  main > section.testimonial blockquote {
    display: flex;
    width: 66.6666666667%;
    border-right: 0 solid transparent;
    background-clip: padding-box !important;
    border-right-width: 2.34375vw;
  }
}
main > section.testimonial blockquote p {
  display: inline-block;
  text-align: center;
  text-indent: 0;
}
main > section.testimonial blockquote cite {
  white-space: nowrap;
}
main > section.articles {
  display: flex;
  flex-wrap: wrap;
  margin-right: -2.34375vw;
}
main > section.articles article.list {
  display: flex;
  width: 83.3333333333%;
  justify-content: space-between;
  overflow: hidden;
  flex-wrap: wrap;
}
@media (min-width: 601px) {
  main > section.articles article.list {
    display: flex;
    width: 66.6666666667%;
  }
}
main > section.articles article.preview {
  overflow: hidden;
  display: flex;
  width: 100%;
  border-right: 0 solid transparent;
  background-clip: padding-box !important;
  border-right-width: 2.34375vw;
  display: block;
  margin-bottom: 7.03125vw;
}
@media (min-width: 601px) {
  main > section.articles article.preview {
    display: flex;
    width: 50%;
    border-right: 0 solid transparent;
    background-clip: padding-box !important;
    border-right-width: 2.34375vw;
    display: block;
    margin-bottom: 2.34375vw;
  }
  main > section.articles article.preview:nth-last-of-type(2) {
    margin-bottom: 0;
  }
}
main > section.articles article.preview header {
  margin-bottom: 1.7233455882vw;
}
main > section.articles article.preview h2 {
  width: 100%;
  line-height: 1.65em;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.img, #film {
  background: #D9D8D6;
}
.img .lazyload, .img .lazyloading, #film .lazyload, #film .lazyloading {
  opacity: 0;
}
.img .lazyloaded, #film .lazyloaded {
  opacity: 1;
  transition: opacity 300ms;
  transition-delay: 0.5s;
}

#index #film {
  position: relative;
  margin: 0 0 2.34375vw 0;
  width: 100%;
}
#index #film video, #index #film img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: block;
  z-index: 10;
}
@media (max-width: 599px) {
  #index #film video, #index #film img {
    left: -50%;
  }
}
@media (max-width: 599px) {
  #index #film:after {
    padding-bottom: 100%;
  }
  #index #film video, #index #film img {
    width: auto !important;
    height: 100%;
  }
}
@media (max-width: 600px) {
  #index #project_index {
    padding-left: 2.34375vw;
    padding-right: 2.34375vw;
  }
}
#index #project_index a {
  display: flex;
  flex-wrap: wrap;
  margin-right: -2.34375vw;
  justify-content: space-between;
  margin-bottom: 2.34375vw;
  text-decoration: none;
  border: none;
  text-decoration: none;
}
#index #project_index a .img {
  display: block;
  object-fit: cover;
  width: 100%;
}
#index #project_index a .slides .img {
  border-right: 0 solid transparent;
  background-clip: padding-box !important;
  border-right-width: 2.34375vw;
}
#index #project_index a .slides {
  justify-content: space-between;
  position: relative;
  display: flex;
  width: 66.6666666667%;
}
@media (max-width: 600px) {
  #index #project_index a .slides {
    display: flex;
    width: 50%;
  }
}
#index #project_index a .index-title {
  display: flex;
  width: 33.3333333333%;
  border-right: 0 solid transparent;
  background-clip: padding-box !important;
  border-right-width: 2.34375vw;
}
@media (max-width: 600px) {
  #index #project_index a .index-title {
    display: flex;
    width: 50%;
    border-right: 0 solid transparent;
    background-clip: padding-box !important;
    border-right-width: 2.34375vw;
  }
}
#index #project_index a .p1, #index #project_index a .p2 {
  display: flex;
  width: 50%;
}
@media (max-width: 600px) {
  #index #project_index a .p1 {
    display: flex;
    width: 100%;
  }
  #index #project_index a .p2 {
    display: none;
  }
}
#index #project_index a .layout-default {
  position: relative;
}
#index #project_index a .layout-alt {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
#index #project_index a .index-title {
  transition: background 1s;
  display: block;
  background: #D9D8D6;
  color: #FFF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.171875vw;
}
#index #project_index a#index-manor-barn .l, #index #project_index a#index-manor-barn .p1, #index #project_index a#index-manor-barn .p2 {
  background: #a19989;
}
@media (max-width: 600px) {
  #index #project_index a#index-manor-barn:hover, #index #project_index a#index-manor-barn.iv {
    position: relative;
  }
  #index #project_index a#index-manor-barn:hover .slides, #index #project_index a#index-manor-barn.iv .slides {
    position: static;
  }
}
#index #project_index a#index-manor-barn:hover .index-title, #index #project_index a#index-manor-barn.iv .index-title {
  background: #a19989;
}
#index #project_index a#index-manor-barn:hover .layout-alt, #index #project_index a#index-manor-barn.iv .layout-alt {
  animation-name: fadeInDelay;
  animation-duration: 2s;
  animation-fill-mode: both;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-delay: -500ms;
}
@media (max-width: 600px) {
  #index #project_index a#index-manor-barn:hover .layout-alt, #index #project_index a#index-manor-barn.iv .layout-alt {
    height: 100%;
    animation-delay: 2000ms;
  }
}
#index #project_index a#index-georgian-rectory .l, #index #project_index a#index-georgian-rectory .p1, #index #project_index a#index-georgian-rectory .p2 {
  background: #414d3a;
}
@media (max-width: 600px) {
  #index #project_index a#index-georgian-rectory:hover, #index #project_index a#index-georgian-rectory.iv {
    position: relative;
  }
  #index #project_index a#index-georgian-rectory:hover .slides, #index #project_index a#index-georgian-rectory.iv .slides {
    position: static;
  }
}
#index #project_index a#index-georgian-rectory:hover .index-title, #index #project_index a#index-georgian-rectory.iv .index-title {
  background: #414d3a;
}
#index #project_index a#index-georgian-rectory:hover .layout-alt, #index #project_index a#index-georgian-rectory.iv .layout-alt {
  animation-name: fadeInDelay;
  animation-duration: 2s;
  animation-fill-mode: both;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-delay: -500ms;
}
@media (max-width: 600px) {
  #index #project_index a#index-georgian-rectory:hover .layout-alt, #index #project_index a#index-georgian-rectory.iv .layout-alt {
    height: 100%;
    animation-delay: 2000ms;
  }
}
#index #project_index a#index-pool-barn .l, #index #project_index a#index-pool-barn .p1, #index #project_index a#index-pool-barn .p2 {
  background: #a9b5c5;
}
@media (max-width: 600px) {
  #index #project_index a#index-pool-barn:hover, #index #project_index a#index-pool-barn.iv {
    position: relative;
  }
  #index #project_index a#index-pool-barn:hover .slides, #index #project_index a#index-pool-barn.iv .slides {
    position: static;
  }
}
#index #project_index a#index-pool-barn:hover .index-title, #index #project_index a#index-pool-barn.iv .index-title {
  background: #a9b5c5;
}
#index #project_index a#index-pool-barn:hover .layout-alt, #index #project_index a#index-pool-barn.iv .layout-alt {
  animation-name: fadeInDelay;
  animation-duration: 2s;
  animation-fill-mode: both;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-delay: -500ms;
}
@media (max-width: 600px) {
  #index #project_index a#index-pool-barn:hover .layout-alt, #index #project_index a#index-pool-barn.iv .layout-alt {
    height: 100%;
    animation-delay: 2000ms;
  }
}
#index #project_index a#index-merchants-house .l, #index #project_index a#index-merchants-house .p1, #index #project_index a#index-merchants-house .p2 {
  background: #cdd1cb;
}
@media (max-width: 600px) {
  #index #project_index a#index-merchants-house:hover, #index #project_index a#index-merchants-house.iv {
    position: relative;
  }
  #index #project_index a#index-merchants-house:hover .slides, #index #project_index a#index-merchants-house.iv .slides {
    position: static;
  }
}
#index #project_index a#index-merchants-house:hover .index-title, #index #project_index a#index-merchants-house.iv .index-title {
  background: #cdd1cb;
}
#index #project_index a#index-merchants-house:hover .layout-alt, #index #project_index a#index-merchants-house.iv .layout-alt {
  animation-name: fadeInDelay;
  animation-duration: 2s;
  animation-fill-mode: both;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-delay: -500ms;
}
@media (max-width: 600px) {
  #index #project_index a#index-merchants-house:hover .layout-alt, #index #project_index a#index-merchants-house.iv .layout-alt {
    height: 100%;
    animation-delay: 2000ms;
  }
}
#index #project_index a#index-brendon-estate-farm .l, #index #project_index a#index-brendon-estate-farm .p1, #index #project_index a#index-brendon-estate-farm .p2 {
  background: #8b9062;
}
@media (max-width: 600px) {
  #index #project_index a#index-brendon-estate-farm:hover, #index #project_index a#index-brendon-estate-farm.iv {
    position: relative;
  }
  #index #project_index a#index-brendon-estate-farm:hover .slides, #index #project_index a#index-brendon-estate-farm.iv .slides {
    position: static;
  }
}
#index #project_index a#index-brendon-estate-farm:hover .index-title, #index #project_index a#index-brendon-estate-farm.iv .index-title {
  background: #8b9062;
}
#index #project_index a#index-brendon-estate-farm:hover .layout-alt, #index #project_index a#index-brendon-estate-farm.iv .layout-alt {
  animation-name: fadeInDelay;
  animation-duration: 2s;
  animation-fill-mode: both;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-delay: -500ms;
}
@media (max-width: 600px) {
  #index #project_index a#index-brendon-estate-farm:hover .layout-alt, #index #project_index a#index-brendon-estate-farm.iv .layout-alt {
    height: 100%;
    animation-delay: 2000ms;
  }
}
#index #project_index a#index-edwardian-house .l, #index #project_index a#index-edwardian-house .p1, #index #project_index a#index-edwardian-house .p2 {
  background: #ac8074;
}
@media (max-width: 600px) {
  #index #project_index a#index-edwardian-house:hover, #index #project_index a#index-edwardian-house.iv {
    position: relative;
  }
  #index #project_index a#index-edwardian-house:hover .slides, #index #project_index a#index-edwardian-house.iv .slides {
    position: static;
  }
}
#index #project_index a#index-edwardian-house:hover .index-title, #index #project_index a#index-edwardian-house.iv .index-title {
  background: #ac8074;
}
#index #project_index a#index-edwardian-house:hover .layout-alt, #index #project_index a#index-edwardian-house.iv .layout-alt {
  animation-name: fadeInDelay;
  animation-duration: 2s;
  animation-fill-mode: both;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-delay: -500ms;
}
@media (max-width: 600px) {
  #index #project_index a#index-edwardian-house:hover .layout-alt, #index #project_index a#index-edwardian-house.iv .layout-alt {
    height: 100%;
    animation-delay: 2000ms;
  }
}
#index #project_index a#index-white-barn .l, #index #project_index a#index-white-barn .p1, #index #project_index a#index-white-barn .p2 {
  background: #e7e5e0;
}
@media (max-width: 600px) {
  #index #project_index a#index-white-barn:hover, #index #project_index a#index-white-barn.iv {
    position: relative;
  }
  #index #project_index a#index-white-barn:hover .slides, #index #project_index a#index-white-barn.iv .slides {
    position: static;
  }
}
#index #project_index a#index-white-barn:hover .index-title, #index #project_index a#index-white-barn.iv .index-title {
  background: #e7e5e0;
}
#index #project_index a#index-white-barn:hover .layout-alt, #index #project_index a#index-white-barn.iv .layout-alt {
  animation-name: fadeInDelay;
  animation-duration: 2s;
  animation-fill-mode: both;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-delay: -500ms;
}
@media (max-width: 600px) {
  #index #project_index a#index-white-barn:hover .layout-alt, #index #project_index a#index-white-barn.iv .layout-alt {
    height: 100%;
    animation-delay: 2000ms;
  }
}
#index #project_index a#index-quantock-view .l, #index #project_index a#index-quantock-view .p1, #index #project_index a#index-quantock-view .p2 {
  background: #414d3a;
}
@media (max-width: 600px) {
  #index #project_index a#index-quantock-view:hover, #index #project_index a#index-quantock-view.iv {
    position: relative;
  }
  #index #project_index a#index-quantock-view:hover .slides, #index #project_index a#index-quantock-view.iv .slides {
    position: static;
  }
}
#index #project_index a#index-quantock-view:hover .index-title, #index #project_index a#index-quantock-view.iv .index-title {
  background: #414d3a;
}
#index #project_index a#index-quantock-view:hover .layout-alt, #index #project_index a#index-quantock-view.iv .layout-alt {
  animation-name: fadeInDelay;
  animation-duration: 2s;
  animation-fill-mode: both;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-delay: -500ms;
}
@media (max-width: 600px) {
  #index #project_index a#index-quantock-view:hover .layout-alt, #index #project_index a#index-quantock-view.iv .layout-alt {
    height: 100%;
    animation-delay: 2000ms;
  }
}
#index #project_index a#index-exe-valley-farm .l, #index #project_index a#index-exe-valley-farm .p1, #index #project_index a#index-exe-valley-farm .p2 {
  background: #a19989;
}
@media (max-width: 600px) {
  #index #project_index a#index-exe-valley-farm:hover, #index #project_index a#index-exe-valley-farm.iv {
    position: relative;
  }
  #index #project_index a#index-exe-valley-farm:hover .slides, #index #project_index a#index-exe-valley-farm.iv .slides {
    position: static;
  }
}
#index #project_index a#index-exe-valley-farm:hover .index-title, #index #project_index a#index-exe-valley-farm.iv .index-title {
  background: #a19989;
}
#index #project_index a#index-exe-valley-farm:hover .layout-alt, #index #project_index a#index-exe-valley-farm.iv .layout-alt {
  animation-name: fadeInDelay;
  animation-duration: 2s;
  animation-fill-mode: both;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-delay: -500ms;
}
@media (max-width: 600px) {
  #index #project_index a#index-exe-valley-farm:hover .layout-alt, #index #project_index a#index-exe-valley-farm.iv .layout-alt {
    height: 100%;
    animation-delay: 2000ms;
  }
}
#index #project_index a#index-moor-cottage .l, #index #project_index a#index-moor-cottage .p1, #index #project_index a#index-moor-cottage .p2 {
  background: #b4a667;
}
@media (max-width: 600px) {
  #index #project_index a#index-moor-cottage:hover, #index #project_index a#index-moor-cottage.iv {
    position: relative;
  }
  #index #project_index a#index-moor-cottage:hover .slides, #index #project_index a#index-moor-cottage.iv .slides {
    position: static;
  }
}
#index #project_index a#index-moor-cottage:hover .index-title, #index #project_index a#index-moor-cottage.iv .index-title {
  background: #b4a667;
}
#index #project_index a#index-moor-cottage:hover .layout-alt, #index #project_index a#index-moor-cottage.iv .layout-alt {
  animation-name: fadeInDelay;
  animation-duration: 2s;
  animation-fill-mode: both;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-delay: -500ms;
}
@media (max-width: 600px) {
  #index #project_index a#index-moor-cottage:hover .layout-alt, #index #project_index a#index-moor-cottage.iv .layout-alt {
    height: 100%;
    animation-delay: 2000ms;
  }
}
#index #project_index a#index-exmoor-lodge .l, #index #project_index a#index-exmoor-lodge .p1, #index #project_index a#index-exmoor-lodge .p2 {
  background: #a9b5c5;
}
@media (max-width: 600px) {
  #index #project_index a#index-exmoor-lodge:hover, #index #project_index a#index-exmoor-lodge.iv {
    position: relative;
  }
  #index #project_index a#index-exmoor-lodge:hover .slides, #index #project_index a#index-exmoor-lodge.iv .slides {
    position: static;
  }
}
#index #project_index a#index-exmoor-lodge:hover .index-title, #index #project_index a#index-exmoor-lodge.iv .index-title {
  background: #a9b5c5;
}
#index #project_index a#index-exmoor-lodge:hover .layout-alt, #index #project_index a#index-exmoor-lodge.iv .layout-alt {
  animation-name: fadeInDelay;
  animation-duration: 2s;
  animation-fill-mode: both;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-delay: -500ms;
}
@media (max-width: 600px) {
  #index #project_index a#index-exmoor-lodge:hover .layout-alt, #index #project_index a#index-exmoor-lodge.iv .layout-alt {
    height: 100%;
    animation-delay: 2000ms;
  }
}
#index #project_index a#index-georgian-estate .l, #index #project_index a#index-georgian-estate .p1, #index #project_index a#index-georgian-estate .p2 {
  background: #99999B;
}
@media (max-width: 600px) {
  #index #project_index a#index-georgian-estate:hover, #index #project_index a#index-georgian-estate.iv {
    position: relative;
  }
  #index #project_index a#index-georgian-estate:hover .slides, #index #project_index a#index-georgian-estate.iv .slides {
    position: static;
  }
}
#index #project_index a#index-georgian-estate:hover .index-title, #index #project_index a#index-georgian-estate.iv .index-title {
  background: #99999B;
}
#index #project_index a#index-georgian-estate:hover .layout-alt, #index #project_index a#index-georgian-estate.iv .layout-alt {
  animation-name: fadeInDelay;
  animation-duration: 2s;
  animation-fill-mode: both;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-delay: -500ms;
}
@media (max-width: 600px) {
  #index #project_index a#index-georgian-estate:hover .layout-alt, #index #project_index a#index-georgian-estate.iv .layout-alt {
    height: 100%;
    animation-delay: 2000ms;
  }
}
#index #project_index a[data-layout-default^=L] .index-title, #index #project_index a[data-layout-default^=P] .index-title {
  order: 1;
}
@media (max-width: 600px) {
  #index #project_index a#index-quantock-view .index-title, #index #project_index a#index-moor-cottage .index-title {
    order: 1;
  }
  #index #project_index a#index-exe-valley-farm .index-title, #index #project_index a#index-exmoor-lodge .index-title {
    order: 0;
  }
}

@media (min-width: 601px) {
  .projects:not(#index) main > section {
    margin-top: 3.515625vw;
    margin-bottom: 3.515625vw;
  }
}
.projects:not(#index) main .prose h1 {
  display: flex;
  width: 83.3333333333%;
  border-right: 0 solid transparent;
  background-clip: padding-box !important;
  border-right-width: 2.34375vw;
}
.projects:not(#index) main .prose h1 span {
  display: none;
}
@media (min-width: 601px) {
  .projects:not(#index) main .prose h1 span {
    display: inline-block;
  }
}
.projects:not(#index) main .prose em {
  display: flex;
  width: 16.6666666667%;
  border-right: 0 solid transparent;
  background-clip: padding-box !important;
  border-right-width: 2.34375vw;
}
.projects:not(#index) main #poi {
  display: flex;
  flex-wrap: wrap;
  margin-right: -2.34375vw;
}
.js .projects:not(#index) main #poi .tab {
  display: none;
}
.js .projects:not(#index) main #poi.tab-1 a.tab-1 {
  color: #89282C;
}
.js .projects:not(#index) main #poi.tab-1 #tab-1.tab {
  display: block;
}
@media (min-width: 601px) {
  .js .projects:not(#index) main #poi.tab-1 #tab-1.tab {
    display: flex;
  }
}
.js .projects:not(#index) main #poi.tab-2 a.tab-2 {
  color: #89282C;
}
.js .projects:not(#index) main #poi.tab-2 #tab-2.tab {
  display: block;
}
@media (min-width: 601px) {
  .js .projects:not(#index) main #poi.tab-2 #tab-2.tab {
    display: flex;
  }
}
.js .projects:not(#index) main #poi.tab-3 a.tab-3 {
  color: #89282C;
}
.js .projects:not(#index) main #poi.tab-3 #tab-3.tab {
  display: block;
}
@media (min-width: 601px) {
  .js .projects:not(#index) main #poi.tab-3 #tab-3.tab {
    display: flex;
  }
}
.js .projects:not(#index) main #poi.tab-4 a.tab-4 {
  color: #89282C;
}
.js .projects:not(#index) main #poi.tab-4 #tab-4.tab {
  display: block;
}
@media (min-width: 601px) {
  .js .projects:not(#index) main #poi.tab-4 #tab-4.tab {
    display: flex;
  }
}
.js .projects:not(#index) main #poi.tab-5 a.tab-5 {
  color: #89282C;
}
.js .projects:not(#index) main #poi.tab-5 #tab-5.tab {
  display: block;
}
@media (min-width: 601px) {
  .js .projects:not(#index) main #poi.tab-5 #tab-5.tab {
    display: flex;
  }
}
.js .projects:not(#index) main #poi.tab-6 a.tab-6 {
  color: #89282C;
}
.js .projects:not(#index) main #poi.tab-6 #tab-6.tab {
  display: block;
}
@media (min-width: 601px) {
  .js .projects:not(#index) main #poi.tab-6 #tab-6.tab {
    display: flex;
  }
}
.projects:not(#index) main #poi nav#tabs {
  display: flex;
  width: 100%;
  border-right: 0 solid transparent;
  background-clip: padding-box !important;
  border-right-width: 2.34375vw;
  justify-content: space-between;
  border-bottom: 1px solid #D9D8D6;
  margin-bottom: 2.34375vw;
  flex-wrap: wrap;
}
@media (min-width: 601px) {
  .projects:not(#index) main #poi nav#tabs {
    display: flex;
    width: 83.3333333333%;
    border-right: 0 solid transparent;
    background-clip: padding-box !important;
    border-right-width: 2.34375vw;
  }
}
.projects:not(#index) main #poi nav#tabs a {
  padding-top: 4.6875vw;
  padding-bottom: 4.6875vw;
  border-bottom: none;
  text-align: center;
  white-space: nowrap;
}
@media (min-width: 601px) {
  .projects:not(#index) main #poi nav#tabs a {
    padding-top: 0;
    padding-bottom: 1.171875vw;
  }
}
.projects:not(#index) main #poi nav#tabs a:last-child {
  text-align: right;
}
.projects:not(#index) main #poi nav#tabs a:first-child {
  text-align: left;
}
.projects:not(#index) main #poi nav#tabs a:hover {
  color: #89282C;
}
@media (max-width: 600px) {
  .projects:not(#index) main #poi nav#tabs a {
    flex-grow: 1;
    flex-basis: 33.333%;
  }
  #exe-valley-farm.projects:not(#index) main #poi nav#tabs [href*="#tab-3"] {
    text-align: right;
  }
  #exe-valley-farm.projects:not(#index) main #poi nav#tabs [href*="#tab-4"] {
    text-align: left;
  }
}
.projects:not(#index) main #poi .tab {
  display: flex;
  width: 100%;
  border-right: 0 solid transparent;
  background-clip: padding-box !important;
  border-right-width: 2.34375vw;
  margin-bottom: 2.34375vw;
}
@media (min-width: 601px) {
  .projects:not(#index) main #poi .tab {
    display: flex;
    width: 83.3333333333%;
    border-right: 0 solid transparent;
    background-clip: padding-box !important;
    border-right-width: 2.34375vw;
  }
}
.js .projects:not(#index) main #poi .tab {
  margin-bottom: 0;
}
.projects:not(#index) main #poi .tab .prose {
  width: 100%;
  margin-bottom: 4.6875vw;
  margin-right: 2.34375vw;
  display: block;
}
@media (min-width: 601px) {
  .projects:not(#index) main #poi .tab .prose {
    width: 37.73584906%;
    margin-bottom: 0;
  }
}
.projects:not(#index) main #poi .tab .prose p {
  margin: 0;
}
.projects:not(#index) main #poi .tab .prose li, .projects:not(#index) main #poi .tab .prose p {
  text-indent: 20pt;
}
.projects:not(#index) main #poi .tab .prose p, .projects:not(#index) main #poi .tab .prose li {
  line-height: 1.7em;
}
.projects:not(#index) main #poi .tab .prose blockquote {
  color: #99999B;
}
.projects:not(#index) main #poi .tab .supporting_image {
  width: 100%;
  display: block;
}
@media (min-width: 601px) {
  .projects:not(#index) main #poi .tab .supporting_image {
    width: 58.49056604%;
  }
}
@media (max-width: 600px) {
  .projects:not(#index) main #poi .tab .supporting_image {
    background: rgba(255, 255, 255, 0.5);
  }
}
.projects:not(#index) main .prose ~ #poi {
  margin-top: 7.03125vw;
}
.projects:not(#index) main #additional_information {
  padding-left: 2.34375vw;
  padding-right: 2.34375vw;
  display: flex;
  flex-wrap: wrap;
  margin-right: -2.34375vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 7.03125vw;
  margin-bottom: 7.03125vw;
}
@media (min-width: 601px) {
  .projects:not(#index) main #additional_information {
    padding-left: 0;
    padding-right: 0;
  }
}
.projects:not(#index) main #additional_information section {
  display: flex;
  width: 50%;
  border-right: 0 solid transparent;
  background-clip: padding-box !important;
  border-right-width: 2.34375vw;
  display: block;
  margin: 0;
}
@media (max-width: 600px) {
  .projects:not(#index) main #additional_information section {
    margin-bottom: 9.375vw;
  }
  .projects:not(#index) main #additional_information section#project_accolades {
    width: 100%;
  }
}
@media (min-width: 601px) {
  .projects:not(#index) main #additional_information section {
    display: flex;
    width: 33.3333333333%;
    border-right: 0 solid transparent;
    background-clip: padding-box !important;
    border-right-width: 2.34375vw;
    display: block;
  }
}
.projects:not(#index) main #additional_information section h2, .projects:not(#index) main #additional_information section dd {
  margin-bottom: 1.7em;
}
.projects:not(#index) main #additional_information section dd:last-of-type {
  margin-bottom: 0;
}
.projects:not(#index) main #additional_information section dd + dd {
  margin-top: -1.7em;
}
.projects:not(#index) main #additional_information section dl {
  margin-left: 16pt;
}
@media (min-width: 601px) {
  .projects:not(#index) main #additional_information section dl {
    margin-left: 36pt;
  }
}
@media (min-width: 1025px) {
  .projects:not(#index) main #additional_information section dl {
    margin-left: 74pt;
  }
}
.projects:not(#index) main #prev_next {
  display: flex;
  flex-wrap: wrap;
  margin-right: -2.34375vw;
  margin-top: 11.71875vw;
  margin-bottom: 11.71875vw;
  padding-left: 2.34375vw;
  padding-right: 2.34375vw;
  flex-wrap: wrap;
}
@media (min-width: 601px) {
  .projects:not(#index) main #prev_next {
    padding-left: 0;
    padding-right: 0;
    margin-top: 7.03125vw;
    margin-bottom: 7.03125vw;
  }
}
.projects:not(#index) main #prev_next a {
  display: flex;
  width: 50%;
  border-right: 0 solid transparent;
  background-clip: padding-box !important;
  border-right-width: 2.34375vw;
  align-items: center;
  border-bottom: none;
}
.projects:not(#index) main #prev_next a > div {
  background: #53565A;
  position: relative;
  width: 65%;
}
@media (min-width: 601px) {
  .projects:not(#index) main #prev_next a > div {
    width: 34.375%;
    margin: 0 2.34375vw;
  }
}
.projects:not(#index) main #prev_next a > div em, .projects:not(#index) main #prev_next a > div .thumb {
  transition: opacity 1s;
}
.projects:not(#index) main #prev_next a > div em {
  display: none;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #89282C;
  top: 0;
  left: 0;
  position: absolute;
  text-align: center;
}
.projects:not(#index) main #prev_next a > div .thumb, .projects:not(#index) main #prev_next a > div img {
  width: 100%;
  height: auto;
  display: block;
}
.projects:not(#index) main #prev_next a:hover > span {
  color: #89282C;
}
.projects:not(#index) main #prev_next a:hover em {
  opacity: 1;
  color: #FFF;
}
.projects:not(#index) main #prev_next a:hover .thumb {
  opacity: 0;
}
.projects:not(#index) main #prev_next a > span {
  display: block;
}
.projects:not(#index) main #prev_next a > span i {
  display: none;
}
@media (max-width: 600px) {
  .projects:not(#index) main #prev_next a > span {
    margin: 0 2.34375vw;
  }
}
@media (min-width: 321px) {
  .projects:not(#index) main #prev_next a > span i {
    display: inline;
  }
}
.projects:not(#index) main #prev_next a:first-of-type {
  justify-content: flex-end;
}

.manual_carousel > div:first-child {
  display: flex;
  width: 100%;
  border-right: 0 solid transparent;
  background-clip: padding-box !important;
  border-right-width: 2.34375vw;
  order: 1;
}
@media (min-width: 601px) {
  .manual_carousel > div:first-child {
    display: flex;
    width: 83.3333333333%;
    border-right: 0 solid transparent;
    background-clip: padding-box !important;
    border-right-width: 2.34375vw;
    order: 0;
  }
}

.manual_carousel, .auto_gallery {
  display: flex;
  flex-wrap: wrap;
  margin-right: -2.34375vw;
  align-items: top;
  justify-content: space-between;
  margin-top: 0;
}
.manual_carousel *, .auto_gallery * {
  width: 100%;
}
.manual_carousel .slick-list, .auto_gallery .slick-list {
  overflow: visible;
}
.manual_carousel .manual_carousel__next_prev, .auto_gallery .manual_carousel__next_prev {
  z-index: 99;
  display: flex;
  width: 100%;
  border-right: 0 solid transparent;
  background-clip: padding-box !important;
  border-right-width: 2.34375vw;
  margin-bottom: 3.515625vw;
  padding: 0 2.34375vw;
  justify-content: space-between;
  align-items: center;
}
@media (min-width: 601px) {
  .manual_carousel .manual_carousel__next_prev, .auto_gallery .manual_carousel__next_prev {
    display: flex;
    width: 16.6666666667%;
    border-right: 0 solid transparent;
    background-clip: padding-box !important;
    border-right-width: 2.34375vw;
    padding: 0;
    margin: 0;
  }
}
.manual_carousel .manual_carousel__next_prev .prev, .manual_carousel .manual_carousel__next_prev .next, .auto_gallery .manual_carousel__next_prev .prev, .auto_gallery .manual_carousel__next_prev .next {
  border-bottom: 0;
  display: block;
  width: 50%;
  cursor: pointer;
  text-align: center;
}
.manual_carousel .manual_carousel__next_prev .prev:hover, .manual_carousel .manual_carousel__next_prev .next:hover, .auto_gallery .manual_carousel__next_prev .prev:hover, .auto_gallery .manual_carousel__next_prev .next:hover {
  color: #89282C;
}
@media (min-width: 601px) {
  .manual_carousel .manual_carousel__next_prev .next, .auto_gallery .manual_carousel__next_prev .next {
    text-align: right;
  }
}
.manual_carousel figure, .auto_gallery figure {
  cursor: pointer;
}
.manual_carousel figure:only-of-type, .auto_gallery figure:only-of-type {
  cursor: default;
}
.manual_carousel figcaption, .auto_gallery figcaption {
  position: absolute;
  width: 37.73584906%;
  margin-right: 2.34375vw;
  margin-top: 3.515625vw;
  text-align: center;
}
@media (min-width: 601px) {
  .manual_carousel figcaption, .auto_gallery figcaption {
    text-align: justify;
  }
}
@media (max-width: 1023px) {
  .manual_carousel figcaption, .auto_gallery figcaption {
    position: relative !important;
    width: 100% !important;
  }
}
#manor-barn .manual_carousel[data-number="2"] figcaption,
#manor-barn .manual_carousel[data-number="3"] figcaption,
#manor-barn .manual_carousel[data-number="7"] figcaption,
#manor-barn .manual_carousel[data-number="8"] figcaption,
#georgian-rectory .manual_carousel[data-number="2"] figcaption,
#georgian-rectory .manual_carousel[data-number="3"] figcaption,
#pool-barn .manual_carousel[data-number="4"] figcaption,
#merchants-house .manual_carousel[data-number="1"] figcaption,
#merchants-house .manual_carousel[data-number="4"] figcaption,
#edwardian-house .manual_carousel[data-number="2"] figcaption,
#white-barn .manual_carousel[data-number="1"] figcaption,
#white-barn .manual_carousel[data-number="4"] figcaption,
#quantock-view .manual_carousel[data-number="2"] figcaption,
#exe-valley-farm .manual_carousel[data-number="4"] figcaption,
#moor-cottage .manual_carousel[data-number="5"] figcaption,
#exmoor-lodge .manual_carousel[data-number="2"] figcaption,
#exmoor-lodge .manual_carousel[data-number="4"] figcaption {
  position: relative !important;
  width: 100% !important;
}
@media (min-width: 601px) {
  #manor-barn .manual_carousel[data-number="2"] .manual_carousel__next_prev,
  #manor-barn .manual_carousel[data-number="3"] .manual_carousel__next_prev,
  #manor-barn .manual_carousel[data-number="7"] .manual_carousel__next_prev,
  #manor-barn .manual_carousel[data-number="8"] .manual_carousel__next_prev,
  #georgian-rectory .manual_carousel[data-number="2"] .manual_carousel__next_prev,
  #georgian-rectory .manual_carousel[data-number="3"] .manual_carousel__next_prev,
  #pool-barn .manual_carousel[data-number="4"] .manual_carousel__next_prev,
  #merchants-house .manual_carousel[data-number="1"] .manual_carousel__next_prev,
  #merchants-house .manual_carousel[data-number="4"] .manual_carousel__next_prev,
  #edwardian-house .manual_carousel[data-number="2"] .manual_carousel__next_prev,
  #white-barn .manual_carousel[data-number="1"] .manual_carousel__next_prev,
  #white-barn .manual_carousel[data-number="4"] .manual_carousel__next_prev,
  #quantock-view .manual_carousel[data-number="2"] .manual_carousel__next_prev,
  #exe-valley-farm .manual_carousel[data-number="4"] .manual_carousel__next_prev,
  #moor-cottage .manual_carousel[data-number="5"] .manual_carousel__next_prev,
  #exmoor-lodge .manual_carousel[data-number="2"] .manual_carousel__next_prev,
  #exmoor-lodge .manual_carousel[data-number="4"] .manual_carousel__next_prev {
    padding-bottom: 4.875625vw;
  }
}

#manor-barn .manual_carousel[data-number="3"] .manual_carousel__next_prev,
#manor-barn .manual_carousel[data-number="7"] .manual_carousel__next_prev,
#exmoor-lodge .manual_carousel[data-number="2"] .manual_carousel__next_prev,
#exmoor-lodge .manual_carousel[data-number="4"] .manual_carousel__next_prev {
  display: none;
}

#manor-barn .img {
  background-color: #a19989;
}

#georgian-rectory .img {
  background-color: #414d3a;
}

#pool-barn .img {
  background-color: #a9b5c5;
}

#merchants-house .img {
  background-color: #cdd1cb;
}

#brendon-estate-farm .img {
  background-color: #8b9062;
}

#edwardian-house .img {
  background-color: #ac8074;
}

#white-barn .img {
  background-color: #e7e5e0;
}

#quantock-view .img {
  background-color: #414d3a;
}

#exe-valley-farm .img {
  background-color: #a19989;
}

#moor-cottage .img {
  background-color: #b4a667;
}

#exmoor-lodge .img {
  background-color: #a9b5c5;
}

#georgian-estate .img {
  background-color: #99999B;
}

.manual_carousel img, .auto_gallery img {
  width: 100%;
  height: auto;
}

.auto_gallery {
  justify-content: center;
}
.auto_gallery > div:first-child {
  display: flex;
  width: 100%;
  border-right: 0 solid transparent;
  background-clip: padding-box !important;
  border-right-width: 2.34375vw;
}
@media (min-width: 601px) {
  .auto_gallery > div:first-child {
    display: flex;
    width: 66.6666666667%;
    border-right: 0 solid transparent;
    background-clip: padding-box !important;
    border-right-width: 2.34375vw;
  }
}
.auto_gallery figure {
  cursor: default;
}

@media (min-width: 601px) {
  #practice .testimonial {
    margin: 3.515625vw 0 !important;
  }
}
@media (min-width: 1025px) {
  #practice main > section {
    margin-top: 3.515625vw;
    margin-bottom: 3.515625vw;
  }
}
#practice #careers {
  margin-bottom: 11.71875vw;
}
@media (min-width: 601px) {
  #practice #careers {
    margin-bottom: 7.03125vw;
  }
}
#practice #careers p {
  display: block;
}
#practice details {
  display: flex;
  width: 100%;
  border-right: 0 solid transparent;
  background-clip: padding-box !important;
  border-right-width: 2.34375vw;
  flex-wrap: wrap;
  justify-content: flex-end;
}
@media (min-width: 601px) {
  #practice details {
    display: flex;
    width: 83.3333333333%;
    border-right: 0 solid transparent;
    background-clip: padding-box !important;
    border-right-width: 2.34375vw;
  }
}
#practice details summary {
  margin-bottom: 4.6875vw;
  width: 100%;
  cursor: pointer;
  list-style-image: none;
  justify-content: space-between;
  border-bottom: none;
}
@media (min-width: 601px) {
  #practice details summary {
    margin-bottom: 2.34375vw;
  }
}
#practice details summary::-webkit-details-marker {
  display: none;
  background: none;
  color: transparent;
}
#practice details summary span, #practice details summary em {
  border-bottom: 1px solid #D9D8D6;
  transition: color 1s;
}
#practice details summary em {
  margin-left: 2.34375vw;
}
@media (min-width: 601px) {
  #practice details summary em {
    float: right;
  }
}
#practice details summary:hover span, #practice details summary:hover em {
  border-bottom: 1px solid transparent;
  color: #99999B;
}
#practice details h2 {
  margin-bottom: 1.36em;
}
#practice details p, #practice details li {
  display: block;
  border: none;
  margin-top: 0;
  margin-bottom: 1.36em;
}
#practice details .agencies, #practice details .apply {
  text-indent: 0;
}
#practice details p, #practice details ul, #practice details h2 {
  margin-left: 16.02901786vw;
}
@media (min-width: 601px) {
  #practice details p, #practice details ul, #practice details h2 {
    width: 79.24528302%;
    margin-left: 20.75471698%;
  }
}

#news.news main {
  margin-top: 4.6875vw;
  position: relative;
}
#news.news main > section {
  margin-top: 0;
}
#news.news main > section.prose {
  padding-left: 0;
}
@media (max-width: 600px) {
  #news.news main > section .article-body, #news.news main > section.prose > h1, #news.news main > section.prose header, #news.news main > section#next-article p {
    padding-left: 2.34375vw;
  }
}
#news.news .previous-post,
#news.news .next-post {
  position: absolute;
  left: 0;
  top: 0;
  width: auto;
  display: block;
  line-height: 1.968em;
  border: transparent;
}
@media (max-width: 600px) {
  #news.news .previous-post,
  #news.news .next-post {
    padding-left: 2.34375vw;
    padding-right: 2.34375vw;
  }
}
#news.news .next-post {
  right: 0;
  left: auto;
}
#news.news h1 {
  display: flex;
  width: 100%;
  border-right: 0 solid transparent;
  background-clip: padding-box !important;
  border-right-width: 2.34375vw;
}
@media (min-width: 601px) {
  #news.news h1 {
    display: flex;
    width: 83.3333333333%;
    border-right: 0 solid transparent;
    background-clip: padding-box !important;
    border-right-width: 2.34375vw;
  }
}
@media (max-width: 600px) {
  #news.news.single article.prose header h1 {
    margin-top: 11.71875vw;
  }
}
#news.news #next-article {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
#news.news article.prose {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-bottom: 0;
}
#news.news article.prose header, #news.news article.prose figure {
  display: flex;
  width: 100%;
  border-right: 0 solid transparent;
  background-clip: padding-box !important;
  border-right-width: 2.34375vw;
  margin-bottom: 1.36em;
}
@media (min-width: 601px) {
  #news.news article.prose header, #news.news article.prose figure {
    margin-bottom: 2.34375vw;
  }
}
#news.news article.prose header {
  display: flex;
  width: 83.3333333333%;
  border-right: 0 solid transparent;
  background-clip: padding-box !important;
  border-right-width: 2.34375vw;
  display: block;
}
@media (min-width: 601px) {
  #news.news article.prose header {
    display: flex;
    width: 66.6666666667%;
    border-right: 0 solid transparent;
    background-clip: padding-box !important;
    border-right-width: 2.34375vw;
    display: block;
  }
}
#news.news article.prose h1, #news.news article.prose h2 {
  line-height: 1.6em;
  border-right: 0 solid transparent;
  background-clip: padding-box !important;
  border-right-width: 0;
  margin-bottom: 0;
}
@media (min-width: 601px) {
  #news.news article.prose h1, #news.news article.prose h2 {
    width: 78.57142857%;
  }
}
#news.news article.prose .article-body h2 {
  width: 100%;
}
#news.news article.prose figure {
  display: flex;
  width: 100%;
  border-right: 0 solid transparent;
  background-clip: padding-box !important;
  border-right-width: 2.34375vw;
  border-right: 0 solid transparent;
  background-clip: padding-box !important;
  border-right-width: 0;
  display: block;
}
@media (min-width: 601px) {
  #news.news article.prose figure {
    display: flex;
    width: 83.3333333333%;
    border-right: 0 solid transparent;
    background-clip: padding-box !important;
    border-right-width: 2.34375vw;
    display: block;
  }
}
#news.news article.prose figure .img {
  background: #FFF;
}
#news.news article.prose figure .img::after {
  padding-bottom: 67.380952381%;
}
#news.news article.prose figure .img img {
  height: 100%;
  width: auto;
}
#news.news article.prose .article-body {
  display: flex;
  width: 83.3333333333%;
  border-right: 0 solid transparent;
  background-clip: padding-box !important;
  border-right-width: 2.34375vw;
  display: block;
}
@media (min-width: 601px) {
  #news.news article.prose .article-body {
    display: flex;
    width: 66.6666666667%;
    border-right: 0 solid transparent;
    background-clip: padding-box !important;
    border-right-width: 2.34375vw;
    display: block;
  }
}
#news.news article.prose .article-body h1, #news.news article.prose .article-body h2, #news.news article.prose .article-body h3, #news.news article.prose .article-body h4, #news.news article.prose .article-body h5, #news.news article.prose .article-body h6 {
  color: #99999B;
  margin-bottom: 1.36em;
}
#news.news article.prose .article-body p, #news.news article.prose .article-body li, #news.news article.prose .article-body ol {
  display: block;
  border: none;
  margin-top: 0;
  margin-bottom: 1.36em;
  width: 100%;
}
#news.news article.prose .article-body strong, #news.news article.prose .article-body b, #news.news article.prose .article-body i, #news.news article.prose .article-body em {
  color: #99999B;
}
#news.news article.prose .article-body > :last-child {
  margin-bottom: 0;
}
#news.news #author {
  text-indent: 0;
  color: #99999B;
}

#contact main > section:first-of-type {
  margin-top: 4.6875vw;
}
#contact main > section:last-of-type {
  margin-bottom: 4.6875vw;
}
#contact #introduction p {
  text-indent: 0;
  display: block;
}
#contact #introduction p#riba {
  margin-top: 4.6875vw;
}
#contact #introduction p#riba img {
  display: block;
  min-width: 75px;
  width: 15%;
}
#contact #studio-addresses {
  display: flex;
  width: 83.3333333333%;
  border-right: 0 solid transparent;
  background-clip: padding-box !important;
  border-right-width: 2.34375vw;
  display: block;
  justify-content: space-between;
}
@media (min-width: 601px) {
  #contact #studio-addresses {
    display: flex;
    width: 66.6666666667%;
    border-right: 0 solid transparent;
    background-clip: padding-box !important;
    border-right-width: 2.34375vw;
  }
}
#contact #studio-addresses address {
  width: 100%;
  display: block;
}
@media (min-width: 601px) {
  #contact #studio-addresses address {
    width: 47.61904762%;
  }
}
#contact #studio-addresses address p {
  text-indent: 0;
  display: block;
  width: 100%;
  border: none;
  margin-top: 0;
  margin-bottom: 4.6875vw;
}
@media (min-width: 601px) {
  #contact #studio-addresses address p {
    margin-bottom: 2.34375vw;
  }
}
#contact #studio-addresses a[href^=tel] {
  border: none;
}
#contact #company {
  padding-left: 2.34375vw;
  padding-right: 2.34375vw;
  display: block;
  text-align: center;
  margin-bottom: 4.6875vw;
  text-indent: 0;
}
@media (min-width: 601px) {
  #contact #company {
    padding-left: 0;
    padding-right: 0;
  }
}

#error-404 main > section {
  margin-top: 4.6875vw;
  margin-bottom: 7.03125vw;
}

footer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-left: 2.34375vw;
  padding-right: 2.34375vw;
  padding-bottom: 9.375vw;
}
@media (min-width: 601px) {
  footer {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 2.34375vw;
  }
}
@media (min-width: 769px) {
  footer {
    flex-wrap: nowrap;
  }
}
footer div, footer nav {
  width: 50%;
}
@media (min-width: 769px) {
  footer div, footer nav {
    width: 33.333% !important;
  }
}
footer #legal {
  width: 70%;
}
footer #legal abbr {
  margin-right: 1.171875vw;
}
footer #legal i {
  display: none;
}
@media (min-width: 961px) {
  footer #legal i {
    display: inline;
  }
}
footer #legal #aloof_studio_credit {
  text-transform: capitalize;
}
@media (min-width: 601px) {
  footer #legal #aloof_studio_credit {
    display: inline;
  }
}
footer #signature {
  text-align: center;
  order: 3;
  width: 100%;
  margin-top: 4.6875vw;
}
footer #signature a {
  text-decoration: none;
  border: none;
}
footer #signature a:hover path {
  fill: #99999B;
}
footer #signature svg {
  width: auto;
}
footer #signature svg path {
  fill: #53565A;
  transition: fill 1s;
}
@media (min-width: 769px) {
  footer #signature {
    order: 0;
    width: 33.333%;
    margin: 0;
  }
}
footer #news_social {
  width: 30%;
  text-align: right;
}
.news footer #news_social a.news {
  color: #89282C;
}
footer #news_social a:nth-of-type(2) {
  margin: 0 1.171875vw;
}