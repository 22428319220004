#grid {

	width:$phone_site_width;
	padding-left:$gap;
	padding-right:$gap;

	@include media(">phone") {
		max-width:$tablet_site_width;
		padding-left:0;
		padding-right:0;
	}

	@include media(">desktop") {
		max-width:$site_width; 
	}

	position:fixed;
	z-index:-1; height:100%;
	opacity:0.1;

	#columns {
		overflow:hidden; height:100%;

		#cols {			
			@include grid(); height:100%;
			display:flex; flex-wrap:wrap; justify-content:flex-end;


			div {
				@include grid_cell(1);			
				height:100%; 
				background:red;

				&:nth-child(even) {opacity:0.25}
				&:nth-child(odd) {opacity:0.5}
				
			}
		}
	}
}

body{
	margin:0 auto;	
	background:$bg;

	&.grid {
		opacity:0.75;
	}

	color:$cool_gray_11;

	max-width:$phone_site_width;

	@include media(">phone") {
		max-width:$tablet_site_width;		
	}

	@include media(">desktop") {		
		max-width:$site_width; 
	}
}

// To trigger nav hide/show
#waypoint{
	width:100%; height:1px; top:1vh; left:0; position:absolute;	

	.grid & {
		background:red;
	}	
}

header#identity_nav {		

	#logo, nav{ 
		opacity:1;
	}

	min-height:100px; max-height:250px; width:100%; 
	display:flex; align-items:center; justify-content:center; box-sizing:border-box;
	$map: (768px: 60px, 1280px:124.5px, 1440px:140px, 1920px:186px, 2560px: 250px);	
	@include poly-fluid-sizing('height', $map); 		

	#logo, nav, #omni div{
		display:flex; transition:opacity $timing; align-items:center; justify-content:space-between;
	}

	#logo, nav#omni div{		

		width:100%;
		padding-left:$gap;
		padding-right:$gap;
		
		@include media(">desktop") {		
			padding:0;
			width:96.7%;
		}

	}

	.js.down:not(.top) & #logo {
		position:fixed; z-index:99; 					
		width:$phone_site_width;

		@include media(">phone") {
			width:$tablet_site_width;
		}

		@include media(">desktop") {
			// 96.7% of 75% (max desktop width)
			width:72.525%;				
		}
	}

	.js.down & {

		#omni {
			background:transparent;

			div{

				transform:translateY(-38px); 

				@include media(">desktop") {
					transform:translateY(-$gap); 	
				}

				
				transition-delay:0.3s; 
			}		
		}
		
	}	

	path {
		transition:fill 0.3s ease;
	}

	.js &.dark #logo {

		svg path {
			fill: $white;
		}
		
	}

	#logo{
	
		&, a, svg{
			min-height:12px;
		}

		a{
			width:auto; display:block;
		}

		svg{			
			width:3.4666666667vw; height:auto; max-height:20px;

			@include media("landscape") {
				width:1.640625vw; max-height:47px;
			}

			path {
				fill:$cool_gray_11;
			}
		}
	}

	nav#omni {
		width:$phone_site_width;

		@include media(">phone") {
			width:$tablet_site_width;
		}

		@include media(">desktop") {
			width:$site_width; 
		}

		position:fixed; top:0; height:$gap; min-height:38px; max-height:88px; background:$bg; justify-content:center; z-index:100;

		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
			left:50%; transform:translate(-50%, 0); 	
		}

		transition:background 1s;

		div {
			will-change: transform; transition:transform 500ms; justify-content:space-evenly; 
			padding-left:3.4666666667vw; padding-right:3.4666666667vw;
			
			@include media("landscape") {
				padding-left:1.640625vw; padding-right:1.640625vw;
			}
		}

		&:hover {
			background:$bg !important;

			div {
				transform:translateY(0) !important;
			}
		}

		.projects & a.projects,
		.practice & a.practice,
		.contact & a.contact,
		.news & a.news {
			color:$red;
		}

		a {
			text-transform:uppercase; text-decoration:none;  transition:color $timing; text-align:center;

			color:$cool_gray_11;

			&:hover {
				color:$red;
			}
		}
	}

}

main {

	overflow: hidden;

}

main > section{
	
	margin:0;

	margin-top:calc($gap * 5);
	margin-bottom:calc($gap * 5);

	@include media(">phone") { 
		
		margin-top:calc($gap * 3);
		margin-bottom:calc($gap * 3);

	}

	display:flex; flex-wrap:wrap; justify-content:flex-end;

	h1 {		
		@include grid_cell(6);
		margin-bottom:calc($gap * 2);		

		@include media(">phone") {
			margin-bottom:$gap;
		}

		@include media(">phone") {
			@include grid_cell(5);		
		}
		justify-content:space-between; 		

	}		

	&#hero {

		margin-top:0 !important;

		.img {

			picture {
				width:100%; height:100%; display:block;
			}

			width:100%;
			height:auto;

			img{

				width:100%; height:auto;

			}

		}

	}

	&.prose, &.testimonial, &.articles, figcaption, &#poi {
		@include media("<=phone") {
			padding-left:$gap;
			padding-right:$gap;			
		}
	}

	&.prose {		

		@include grid();

		p {
			@include grid_cell(5);

			@include media(">phone") {
				@include grid_cell(4);
			}				
		}

		figure {
						
			@include grid_cell(3);
			display:block;

			p {
				@include grid_gutter(0);
				display:block; width:100%;
	
			}
		}

		picture {						
			width:100%;					
		}
		
		img {			
			margin-bottom:$gap;
			width:100%; height:auto;
		}

	}
	
	&.testimonial {

		@include media(">phone") { 
		
			margin:calc($gap * 5) 0 !important;

		}

		@include grid();

		blockquote {

			@include grid_cell(5);
			
			@include media(">phone") {
				@include grid_cell(4);	
			}

			display:block;

			p {
				display:inline-block; text-align:center; text-indent:0;
			}

			cite {
				white-space:nowrap;
			}

		}

	}


	&.articles{

		@include grid();

		article.list{
			@include grid_cell(5, $gutter:0);
			
			@include media(">phone") {
				@include grid_cell(4, $gutter:0);
			}

			justify-content:space-between; overflow:hidden; flex-wrap:wrap;

		}			

		article.preview {
			overflow:hidden;

			@include grid_cell(5,5);
			display:block;
			margin-bottom:calc($gap * 3);

			@include media(">phone") {
				@include grid_cell(2,4);				
				display:block;
				margin-bottom:$gap;

				&:nth-last-of-type(2) {
					margin-bottom:0;
				}
			}			

			header{
				 margin-bottom:calc($gap / $line_height);
			}

			h2{				
				width:100%; line-height:1.65em;
				overflow-x: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;					
			}
		}
	}

}

.img, #film{

	background:$cool_gray_1;
		
	.lazyload, .lazyloading{
	  opacity: 0; 
	}

	.lazyloaded{
	  // Fade in the loaded image
	  opacity:1;
	  transition: opacity 300ms;
	  transition-delay:0.5s;
	}

}

// Specfic Areas

@import 'home.scss'; 
@import 'projects.scss';
@import 'practice.scss';
@import 'news.scss';
@import 'contact.scss';

#error-404 {

	main > section {

		margin-top:calc($gap * 2);
		margin-bottom:calc($gap * 3);		

	}

}


footer {
	display:flex; justify-content:space-between; flex-wrap:wrap;

	padding-left:$gap;
	padding-right:$gap;
	padding-bottom:calc($gap * 4);

	@include media(">phone") {		
		padding-left:0;
		padding-right:0;
		padding-bottom:$gap;
	}

	@include media(">tablet") {		
		flex-wrap:nowrap;
	}

	div, nav{		
		width:50%;

		@include media(">tablet") {
			width:33.333% !important;
		}
	}

	#legal {

		width:70%;

		abbr {
			margin-right:calc($gap / 2);
		}
	
		i {
			display:none;

			@include media(">tablet960") {
				display:inline;				
			}
		}		

		#aloof_studio_credit{
			text-transform:capitalize;			

			@include media(">phone") {
				display:inline;								
			}
		}
	}

	#signature {
		text-align:center; order:3; width:100%; margin-top:calc($gap * 2);

		a{
			text-decoration:none; border:none;

			&:hover path{
				fill: $cool_gray_7;
			}
		}

		svg {			
			width:auto;

			path {
				fill: $cool_gray_11; transition: fill $timing;
			}
		}

		@include media(">tablet") {
			order:0; width:33.333%; margin:0;
		}
	}

	#news_social{

		width:30%;
		
		.news & a.news {
			color:$red;
		}

		text-align:right;		

		a:nth-of-type(2){ 			
			margin:0 calc($gap / 2);
		}
	}
}