#news.news {

	main {

		margin-top: $gap * 2;
		position:relative;

	}

	main > section {
		margin-top:0;

		&.prose {
			padding-left:0;
		}

		.article-body, &.prose > h1, &.prose header, &#next-article p {
			@include media("<=phone") {
				padding-left:$gap;				
			}
		}
	}

	.previous-post,
	.next-post {
		
		position:absolute; left:0; top:0; width:auto; display:block; line-height:1.968em; border:transparent;

		@include media("<=phone") {
			padding-left:$gap;				
			padding-right:$gap;				
		}

	}

	.next-post {

		right:0; left:auto;

	}
	
	h1 {
		@include grid_cell(6);

		@include media(">phone") {
			@include grid_cell(5);		
		}
	}

	&.single {
		
		@include media("<=phone") {
			article.prose header h1 {
				margin-top:$gap * 5;
			}
		}

	}	

	#next-article {
		display:flex; flex-wrap:wrap; justify-content:flex-end;
	}

	article.prose {
		display:flex; flex-wrap:wrap; justify-content:flex-end;

		margin-bottom: 0;

		header, figure {
			@include grid_cell(6);
			margin-bottom:#{$line_height}em;

			@include media(">phone") { 
				margin-bottom:$gap;
			}
		}

		header{	
			@include grid_cell(5);					
			display:block;

			@include media(">phone") {
				@include grid_cell(4);		
				display:block;
			}

		}	

		h1, h2 {
			// width:100%;			

			line-height:1.6em;
			@include grid_gutter(0); 
			margin-bottom:0;

			@include media(">phone") {
				width:78.57142857%;
			}
			
		}

		.article-body h2{
			width:100%;
		}

		figure {
			@include grid_cell(6);						
			@include grid_gutter(0);
			display:block;

			@include media(">phone") {
				@include grid_cell(5);		
				display:block;				
			}			

			.img {

				background:$white;
				
  				&::after {
    				padding-bottom: calc((283 / 420) * 100%);        
  				}  

	  			img{

	    			height:100%; width:auto;

	  			}			

			}		
		}

		.article-body {

			@include grid_cell(5);					
			display:block;

			@include media(">phone") {
				@include grid_cell(4);		
				display:block;
			}

			h1, h2, h3, h4, h5, h6 {
			
				color:$cool_gray_7;
				margin-bottom:#{$line_height}em;
				
			}

			p, li, ol {

				display:block; border:none; margin-top:0; margin-bottom:#{$line_height}em; width:100%;				

			}

			strong, b, i, em {

				color:$cool_gray_7;

			}

			& > :last-child {
				margin-bottom:0;
			}
			
		}		

	}

	#author {

		text-indent:0; color:$cool_gray_7;

	}

}