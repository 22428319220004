#index{

	#film {
		position:relative;
		margin:0 0 $gap 0;	
		width:100%;

		video, img {
			position:absolute; top:0; left:0; width:100%; display: block; z-index:10;

			@include media("<phone") {
				left:-50%; 

			}			
		}		

		@include media("<phone") {

			&:after {
				padding-bottom: calc((1 / 1) * 100%);				
			}
			
			video, img {
				
				width:auto !important;
				height:100%;

			}		

		}

	}

	#project_index{		

		@include media("<=phone") {
			padding-left:$gap;
			padding-right:$gap;			
		}
				
		a{			
			@include grid();

			@include media("<=phone") {					
				// position:relative;
			}

			justify-content:space-between; margin-bottom:$gap; 
			text-decoration:none; border:none;						

		  	text-decoration:none;	

		  	.img{
		  		display:block;
		  		object-fit: cover;
		  		width: 100%;		  		
		  	}

		  	.slides .img {		
				@include grid_gutter();
		  	}		  	

		  	.slides{
		  		justify-content:space-between; position:relative;
		  		@include grid_cell($gutter:0);

		  		@include media("<=phone") {					
					@include grid_cell(2,4, $gutter:0);
					// position:static;
				}	
		  	}

		  	.index-title {				
				@include grid_cell(2);

				@include media("<=phone") {
					@include grid_cell(2, 4);
				}
		  	}

		  	.p1, .p2 {
		  		@include grid_cell(1, 2, $gutter:0);		  		
		  	}

		  	@include media("<=phone") {
				.p1 {
					@include grid_cell(2, 2, $gutter:0);
				}

				.p2 {
					display:none;					
				}
			}

		  	.layout-default{ 
				position:relative;	

				
		  	}

		  	.layout-alt{
				position:absolute; top:0; left:0; opacity:0;				
			}

		  	.index-title { 		  						
				transition:background $timing; display:block;
				background:$index_button; 
				color:$index_text;
				display:flex; justify-content:space-between; align-items:center;
				padding:0 calc($gap / 2);
			}

			@each $project, $colour in $projects {
			
			&#index-#{$project} {
				.l, .p1, .p2 {
					background:$colour;
				}
			}				

			  &#index-#{$project}:hover,
			  &#index-#{$project}.iv {
			  	@include media("<=phone") {	

			  		position:relative;

			  		.slides {
						position:static;
			  		}

			  	}								

			  	.index-title {
			    	background:$colour;
			  	}
					
				.layout-alt{	
					animation-name: fadeInDelay;
					animation-duration: 2s;
  					animation-fill-mode: both;
					animation-direction: alternate;					
					animation-iteration-count: infinite;							
					animation-delay: -500ms;

					@include media("<=phone") {	
						height:100%;
						animation-delay:2000ms;
					}

				}

			  }   
			}

		  	&[data-layout-default^="L"],
		  	&[data-layout-default^="P"] {		  
		  		.index-title {		  					  			
  					order:1;
		  		}
		  	}

		  	@include media("<=phone") {	
				&#index-quantock-view,
				&#index-moor-cottage {
					.index-title {						
						order:1;
					}
				}

				&#index-exe-valley-farm,
				&#index-exmoor-lodge {
					.index-title{						
						order:0;
					}
				}
			}
		}

	}

}