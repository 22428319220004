// Colours

$bg: #F5F5F5;

$white: #FFF;
$index_text: $white;
$image_filler: rgba(255,255,255,0.5);

$cool_gray_1: #D9D8D6;
$index_button: $cool_gray_1;
$underline: $cool_gray_1;

$cool_gray_7: #99999B;
$light_text: $cool_gray_7;

$cool_gray_9: #75787B;

$cool_gray_11: #53565A;

$dark_text: $cool_gray_11;

$pms_7622C_red: #89282C;
$red: $pms_7622C_red;

$pms_344C_mint: #A0DAB3;
$mint: $pms_344C_mint;

$pms_9542C_sage: #CDDACD;
$sage: $pms_9542C_sage;

$pms_611C_mustard: #D7C826;
$mustard: $pms_611C_mustard;

$pms_3282C_jade: #008578;
$jade: $pms_3282C_jade;

$pms_5773C_olive: #899064;
$olive: $pms_5773C_olive;

$pms_7736C_forest: #385542;
$forest:$pms_7736C_forest;

// Colours on the Index
$projects: (
	"manor-barn": #a19989,
	"georgian-rectory": #414d3a,
	"pool-barn": #a9b5c5,
	"merchants-house": #cdd1cb, 
	"brendon-estate-farm": #8b9062, 
	"edwardian-house": #ac8074,
	"white-barn": #e7e5e0,
	"quantock-view": #414d3a,
	"exe-valley-farm": #a19989,
	"moor-cottage": #b4a667,
	"exmoor-lodge": #a9b5c5,
	"georgian-estate": $cool_gray_7
	);

// Common measurements
$gap:2.34375vw;
$gap_perc:2.34375%;
$site_width:75%;
$tablet_site_width: 90%;
$phone_site_width: 100%;
$scale: 0.84375;
$line_height: 1.36;
$indent: 40pt;

// Transitions
$timing: 1s;