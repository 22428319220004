#contact {

	main > section:first-of-type {		

		margin-top:$gap * 2;

	}

	main > section:last-of-type {		

		margin-bottom:$gap * 2;

	}

	#introduction {
	
		p {

			text-indent: 0; display:block;

			&#riba {
				margin-top:$gap * 2;

				img {
					display:block; min-width:75px; width:15%;
				}			
			}

		}		

	}

	#studio-addresses {

		@include grid_cell(5);
		display:block;

		@include media(">phone") {
			@include grid_cell(4);		
		}

		justify-content:space-between;

		address {						
			width:100%;
			
			@include media(">phone") {
				// 2 col nested in a 4 col
				width:47.61904762%; 
			}

			display:block;

			p {

				text-indent:0; display:block; width:100%; border:none; margin-top:0; 
				
				margin-bottom:$gap * 2;

				@include media(">phone") {
					margin-bottom:$gap;					
				}

			}

		}		

		a[href^="tel"]{
			border:none;
		}

	}

	#company {
		
		padding-left:$gap;
		padding-right:$gap;		

		@include media(">phone") {		
			padding-left:0;
			padding-right:0;			
		}
		
		display:block; text-align: center; margin-bottom:$gap * 2; text-indent:0;

	}		

}